import {
    USER_DATA
} from "./types";

export const setUserData = (user) => {
    return {
        type: USER_DATA,
        payload: user
    }
}
