export default class MyStorage {
    user_info = "user_info";
  

    setItem(key, value) {
        localStorage.setItem(key, "".concat(value));
    }

    getItem(key) {
        return localStorage.getItem(key);
    }

    removeItem(key) {
        return localStorage.removeItem(key);
    }

    clearStorage() {
        // return localStorage.clear();
        return this.rmUserInfo()
    }

    setUserInfo(info) {
        this.setItem(this.user_info, info);
    }

    async getUserInfo() {
        return this.getItem(this.user_info) || '';
    }

}
