import {useEffect} from 'react';
import { useRoutes } from 'react-router-dom';
import { connect } from 'react-redux';
import { setUserData } from '../redux/actions/ActionsUserData';
import MyStorage from '../store/MyStorage';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import config from 'config';

// ==============================|| ROUTING RENDER ||============================== //

function ThemeRoutes(props) {

  const checkLogin = () => {
      new MyStorage().getUserInfo().then(user => {
          if (user) {
              user = JSON.parse(user);
              props.setUserData(user);
          } else {
          }
      });
  }

  useEffect(() => {
    checkLogin();
  },[]);
  // , config.basename
  return useRoutes([ props.user && props.user.user ? MainRoutes : AuthenticationRoutes]);
}

const mapStateToProps = (state) => {
    return {
        user: state.user_data,
    }
}

const actions = {
    setUserData,
}


export default connect(mapStateToProps, actions)(ThemeRoutes);
