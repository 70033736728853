import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';

import ReducerUserData from '../redux/reducers/ReducerUserData';
// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    customization: customizationReducer,
    user_data: ReducerUserData,
});

export default reducer;
