import dashboard from './dashboard';
import pages from './pages';
import utilities from './utilities';
import other from './other';

// ==============================|| MENU ITEMS ||============================== //
// Documentacion anada other
//dashboard,
const menuItems = {
    items: [ pages, utilities, ]
};

export default menuItems;
