import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase, Typography } from '@mui/material';

// project imports
import config from 'config';
import Logo from 'ui-component/Logo';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
    <ButtonBase disableRipple component={Link} to={config.defaultPath}>
        <Logo /> 
        <Typography 
            variant='body1' 
            sx={{ fontWeight: 'bold', marginLeft: '15px', fontSize: '18px', fontFamily: 'sans-serif'  }}>AFI</Typography> 
    </ButtonBase>
);

export default LogoSection;
