// material-ui
//import { useTheme } from '@mui/material/styles';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 * https://www.svgviewer.dev/svg-to-react-jsx
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    //const theme = useTheme();

    return (
        /**
         * if you want to use image instead of svg uncomment following, and comment out <svg> element.
         *
         * <img src={logo} alt="Berry" width="100" />
         *
         */

  <svg
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 201 195"
    style={{
      enableBackground: "new 0 0 201 195",
    }}
    width={40}
    height={40}
  >
    <g />
    <g>
      <path
        className="st3"
        d="M168.36,165.96c0.69-1.67,0.63-3.55-0.16-5.17c-12.01-24.43-31.49-44.74-56.33-58.75             C87.1,88.08,59.72,81.93,32.67,84.25c-1.8,0.15-3.45,1.08-4.52,2.53c-1.07,1.46-1.46,3.3-1.07,5.06l3.94,17.87             c0.68,3.07,3.52,5.17,6.66,4.9c20.28-1.74,40.86,2.91,59.53,13.43c18.73,10.56,33.39,25.82,42.39,44.14             c0.58,1.17,1.48,2.09,2.55,2.69c1.5,0.85,3.34,1.07,5.09,0.48l17.34-5.85C166.29,168.92,167.67,167.63,168.36,165.96"
      />
      <linearGradient
        id="SVGID_2_"
        gradientUnits="userSpaceOnUse"
        x1={70.2321}
        y1={157.4541}
        x2={187.8711}
        y2={39.8151}
      >
        <stop
          offset={0}
          style={{
            stopColor: "#F49C27",
          }}
        />
        <stop
          offset={0.0965}
          style={{
            stopColor: "#F49C27",
          }}
        />
        <stop
          offset={0.3035}
          style={{
            stopColor: "#F39825",
          }}
        />
        <stop
          offset={0.5086}
          style={{
            stopColor: "#F18C1E",
          }}
        />
        <stop
          offset={0.7122}
          style={{
            stopColor: "#ED7814",
          }}
        />
        <stop
          offset={0.853}
          style={{
            stopColor: "#EA660A",
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#EA660A",
          }}
        />
      </linearGradient>
      <path
        style={{
          fill: "url(#SVGID_2_)",
        }}
        d="M145.48,14.64L24.96,86.93c-3.47,2.08-5.8,5.39-6.57,9.33c-0.8,4.14,0.29,8.56,3,12.14             c1.47,1.94,3.3,3.49,5.32,4.63c5.09,2.87,11.39,3.07,16.38,0.07l91.13-54.66c1.87-1.12,4.26,0.23,4.25,2.42l-0.55,105.55             c-0.04,5.71,3.25,11.12,8.57,14.12c2.06,1.16,4.27,1.88,6.58,2.13c4.45,0.5,8.81-0.82,11.97-3.63c2.99-2.66,4.65-6.35,4.67-10.39             l0.74-139.8c0.03-5.69-3.23-11.08-8.56-14.11c-2.66-1.5-5.57-2.25-8.44-2.25C150.64,12.49,147.88,13.2,145.48,14.64"
      />
    </g>
  </svg>


    );
};

export default Logo;
