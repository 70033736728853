// assets
import { IconAdjustmentsHorizontal, IconUsers } from '@tabler/icons';

// constant
const icons = {
    IconAdjustmentsHorizontal,
    IconUsers
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
    id: 'mantenimiento',
    title: 'Dashboard',
    caption: 'Gestión de AFI',
    type: 'group',
    children: [
        {
            id: 'settings',
            title: 'Configuraciones',
            type: 'collapse',
            icon: icons.IconAdjustmentsHorizontal,
            children: [
                {
                    id: 'categoria',
                    title: 'Categoria',
                    type: 'item',
                    url: '/categoria',
                    icon: icons.IconBrandChrome,
                    breadcrumbs: false
                },
                {
                    id: 'servicios',
                    title: 'Asociados Afi',
                    type: 'item',
                    url: '/servicios',
                    breadcrumbs: false
                },
            ]
        },
        {
            id: 'users',
            title: 'Gestión de Costumer',
            type: 'collapse',
            icon: icons.IconUsers,
            children: [
                {
                    id: 'register3',
                    title: 'Crear Administrador',
                    type: 'item',
                    url: '/pages/nuevo-usuario',
                    breadcrumbs: false
                },
                {
                    id: 'usuarios',
                    title: 'Todos los Costumers',
                    type: 'item',
                    url: '/usuario',
                },
                {
                    id: 'profesionales',
                    title: 'Profesionales',
                    type: 'item',
                    url: '/profesionales',
                    breadcrumbs: false
                },
                {
                    id: 'eliminados',
                    title: 'Costumer Eliminados',
                    type: 'item',
                    url: '/eliminados',
                    icon: icons.IconBrandChrome,
                    breadcrumbs: false
                },

            ]
        }
    ]
};

export default pages;
